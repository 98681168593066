import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import PoliciesMap from './maps/policies/PoliciesMap'

import RegionLinks from './components/RegionLinks'
import RegionLandingPage from './components/RegionLandingPage'
import Header from './components/Header'

import './App.css'
import RootStore, { RootStoreContext } from './stores/rootStore'

// NOTE: we only show the list of regions at the root of the app if NOT in production;
// see https://app.asana.com/0/1140737766672633/1192050134738217
const SHOULD_SHOW_REGION_LINKS = process.env.NODE_ENV !== 'production'

export function App() {
  return (
    <div className="App">
      <div className="App-header">
        <Switch>
          <Route path="/:region_id/:type" component={Header} />
          <Route path="/">
            <h1>
              <img src="/populus_logo.png" alt="Populus" width="155" />
            </h1>
          </Route>
        </Switch>
      </div>
      <Switch>
        {SHOULD_SHOW_REGION_LINKS ? (
          <Route exact path="/">
            <RegionLinks />
          </Route>
        ) : (
          <Route exact path="/" render={() => (window.location = 'https://www.populus.ai')} />
        )}
        <Route exact path="/:region_id" component={RegionLandingPage} />
        <Route path="/:region_id/policies/:policy_uuid?" component={PoliciesMap} />
      </Switch>
    </div>
  )
}

const rootStore = new RootStore() // this should never change

function AppWithStores() {
  useEffect(() => rootStore.initialize(), [])

  if (!rootStore.loaded) return <div>Loading...</div>

  return (
    <RootStoreContext.Provider value={rootStore}>
      <App />
    </RootStoreContext.Provider>
  )
}

export default observer(AppWithStores)
