import React from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'semantic-ui-react'
import { useRootStore } from '../stores/rootStore'

const RegionLinks = () => {
  const rootStore = useRootStore()

  return (
    <div className="App-content">
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Region</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {rootStore.getRegions().map(region => (
            <Table.Row key={region}>
              <Table.Cell>
                <Link to={`/${region}`}>{rootStore.getText(region)}</Link>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}

export default RegionLinks
