import React from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'semantic-ui-react'

import { useRootStore } from '../stores/rootStore'

const RegionLandingPage = ({
  match: {
    params: { region_id },
  },
}) => {
  const rootStore = useRootStore()

  return (
    <div className="App-content">
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{rootStore.getText(region_id)}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {rootStore.getMaps(region_id).includes('policies') && (
            <Table.Row>
              <Table.Cell>
                <Link to={`/${region_id}/policies`}>Policies</Link>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  )
}

export default RegionLandingPage
