import { Button, Dropdown } from 'semantic-ui-react'
import React from 'react'

import { MultipleDataDownloadOptions } from '../model/types'

/**
 * @param isDisabled - true if the button group should appear disabled
 * @param dataObjects - an array of objects that make up the dropdown options
 * @param absolutePosition - true if the component should be wrapped in a div with position = absolute
 * @return a button component with a dropdwon that allows a user to download multiple files at once or each file individually
 */
export default function MultipleDataDownloadButton({
  isDisabled,
  dataObjects,
  absolutePosition = true,
}: {
  isDisabled?: boolean
  absolutePosition: boolean
  dataObjects: MultipleDataDownloadOptions[]
}) {
  const downloadAll = () => dataObjects.forEach(item => item.downloadFunction())

  const options = dataObjects?.map((item, idx) => {
    const icon = item.format === 'geojson' ? 'globe' : 'table'
    return {
      key: idx,
      icon: icon,
      text: item.filename,
      value: item.format,
      onClick: item.downloadFunction,
    }
  })

  const buttonGroup = (
    <Button.Group size="mini">
      <Button icon="download" title="Download All" disabled={isDisabled} onClick={downloadAll} />
      <Dropdown
        disabled={isDisabled}
        direction="left"
        className="button icon"
        floating
        options={options}
        trigger={<React.Fragment />}
      />
    </Button.Group>
  )

  if (!absolutePosition) {
    return buttonGroup
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        padding: 'inherit',
      }}
    >
      {buttonGroup}
    </div>
  )
}
