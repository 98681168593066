import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useRootStore } from '../../stores/rootStore'

import PoliciesDetails from './PoliciesDetails'
import Map from '../../components/Map'

const PoliciesMap = ({
  history,
  match: {
    params: { policy_uuid, region_id },
  },
  location: { search },
}) => {
  const rootStore = useRootStore()
  const { mdsPolicyStore } = rootStore

  useEffect(() => {
    document.title = 'Populus -- Mobility Policies'
  })

  useEffect(() => {
    mdsPolicyStore.initialize(region_id)
  }, [mdsPolicyStore, region_id])

  const handlePolicy = (policy, clickLngLat) => {
    let path = `/${policy.regionId}/policies/${policy.uuid}`
    if (clickLngLat) {
      path += `?click=${encodeURIComponent(JSON.stringify(clickLngLat))}`
    }

    if (policy_uuid) {
      history.replace(path)
    } else {
      history.push(path)
    }
  }

  if (!mdsPolicyStore.loaded) return null

  return (
    <Map
      mapType={'zones'}
      data={mdsPolicyStore.orderedPolicies}
      handleSelectItem={handlePolicy}
      DetailsComponent={PoliciesDetails}
      selectedItemId={policy_uuid}
      selectedRegionId={region_id}
      locationSearch={search}
      selectedRegion={rootStore.regions.get(region_id)}
      regionLayers={rootStore.regionLayers.get(region_id)}
    />
  )
}

export default observer(PoliciesMap)
