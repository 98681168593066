import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'
import mapboxgl from 'mapbox-gl'
import firebase from 'firebase/app'

import 'semantic-ui-css/semantic.min.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'

import './i18n'

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

import config from './config'

mapboxgl.accessToken = config.mapbox.accessToken

firebase.initializeApp(config.firebase)

ReactGA.initialize('UA-139701967-1')
ReactGA.ga('set', 'anonymizeIp', true) // GDPR compliance, mask last part of IP address

if (process.env.NODE_ENV !== 'production') {
  ReactGA.ga('set', 'sendHitTask', null)
}

const history = createBrowserHistory()
history.listen(location => {
  ReactGA.set({ page: location.pathname })
  ReactGA.pageview(location.pathname)
})

const AppRouter = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname)
  }, [])
  return (
    <Router history={history}>
      <App />
    </Router>
  )
}
ReactDOM.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>,
  document.getElementById('app')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
