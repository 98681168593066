import React from 'react'

import _ from 'lodash'

import mapboxgl from 'mapbox-gl'
import MapboxLanguage from '@mapbox/mapbox-gl-language'
import { Ref } from '@fluentui/react-component-ref'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import bbox from '@turf/bbox'

import './Map.css'

import { policiesFeatureCollection } from '../utils/mapUtils'

class Map extends React.Component {
  constructor(props) {
    super(props)

    this.selectedItemCardRef = React.createRef()

    this.state = {
      map: null,
      selectedLayerUUID: this.regionLayersDropdownOptions[0]?.value,
    }
  }

  componentDidMount() {
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/light-v9',
      ...this.regionCameraOptions,
    })

    // https://github.com/mapbox/mapbox-gl-language/
    map.addControl(new MapboxLanguage())

    // https://docs.mapbox.com/mapbox-gl-js/example/mapbox-gl-geocoder/

    map.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl,
        options: { types: 'locality,neighborhood,address' },
      })
    )

    const { selectedItemId, data } = this.props

    map.on('load', () => {
      const policyToShow = policiesFeatureCollection(data, selectedItemId)
      map.addSource('selected-policy', {
        type: 'geojson',
        data: policyToShow,
      })
      map.addLayer({
        id: 'selected-policy',
        type: 'fill',
        source: 'selected-policy',
        paint: {
          'fill-color': ['get', 'color'],
          'fill-opacity': 0.5,
        },
      })

      this.setState({ map })
    })

    if (this.selectedItemCardRef.current) {
      this.selectedItemCardRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }

    if (this.boundsForSelectedItem) map.fitBounds(this.boundsForSelectedItem, { padding: 50 })

    this.map = map
  }

  componentWillUnmount() {
    if (this.map) this.map.remove()
  }

  get selectedRegion() {
    return this.props.selectedRegion
  }

  get regionLayers() {
    return this.props.regionLayers || {}
  }

  get regionLayersDropdownOptions() {
    const layers = this.regionLayers
    return layers.length > 0
      ? layers.map(o => ({
          text: o.layer_name,
          value: o.layer_uuid,
        }))
      : []
  }

  get regionCameraOptions() {
    const {
      position: { mapCenter, mapZoom },
    } = this.selectedRegion

    return {
      center: [mapCenter.lng, mapCenter.lat],
      zoom: mapZoom,
    }
  }

  get popupLngLat() {
    const query = new URLSearchParams(this.props.locationSearch)
    return query.get('click') && JSON.parse(query.get('click'))
  }

  get boundsForSelectedItem() {
    if (!this.selectedItemObject) return null
    return bbox(this.selectedItemObject.geojson)
  }

  componentDidUpdate(prevProps) {
    const { map } = this

    if (prevProps.selectedRegionId !== this.props.selectedRegionId) {
      map.jumpTo(this.regionCameraOptions)
      this.setState({ selectedLayerUUID: this.regionLayersDropdownOptions[0]?.value })
    }
    if (prevProps.selectedItemId !== this.props.selectedItemId) {
      if (this.boundsForSelectedItem) {
        map.fitBounds(this.boundsForSelectedItem, { padding: 50 })
      } else {
        // item was un-selected; zoom out to see entire region
        map.flyTo(this.regionCameraOptions)
      }
      map
        .getSource('selected-policy')
        ?.setData(policiesFeatureCollection(this.props.data, this.props.selectedItemId))
      if (this.selectedItemCardRef.current) {
        this.selectedItemCardRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }
    }
  }

  get selectedItemObject() {
    return this.props.data.find(item => item.uuid === this.props.selectedItemId)
  }

  get selectedLayerGeoJSON() {
    const { selectedLayerUUID } = this.state

    const layer = _.find(this.regionLayers, layer => layer.layer_uuid === selectedLayerUUID)
    return layer?.layer_geometry
  }

  render() {
    const { children, data, DetailsComponent, handleSelectItem, selectedItemId } = this.props

    const { selectedRegion } = this

    return (
      <div className="main-content flex-container flex-direction-row flex-item">
        <div className="left-sidebar">
          {selectedRegion.organizationLogoUrl && (
            <img
              alt=""
              src={selectedRegion.organizationLogoUrl}
              style={{ width: 250, height: 'auto', display: 'block', margin: '1.5em auto' }}
            />
          )}
          <>
            <div data-testid="closure-details">
              {data.map(d => {
                const isSelected = d.uuid === selectedItemId

                return (
                  <Ref key={d.uuid} innerRef={isSelected && this.selectedItemCardRef}>
                    <DetailsComponent
                      onClick={() => handleSelectItem(d)}
                      isSelected={isSelected}
                      item={d}
                    />
                  </Ref>
                )
              })}
            </div>
            <div className="left-sidebar-footer">
              <img
                src="/Populus_SecondaryLogo_Dark.png"
                alt="Populus"
                style={{ display: 'block', margin: 'auto' }}
                width="155"
              />
            </div>
          </>
        </div>
        <div className="flex-item" style={{ position: 'relative' }}>
          <div
            ref={el => (this.mapContainer = el)}
            className="mapbox-container"
            style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
          />
          {children}
        </div>
      </div>
    )
  }
}

export default Map
