import * as FileSaver from 'file-saver'

import { MdsDataSpec } from '../model/types'

export function downloadMds(filename: string, geojson: MdsDataSpec) {
  let blob
  const text = JSON.stringify(geojson)
  blob = new Blob([text], { type: 'application/json' })

  if (blob) FileSaver.saveAs(blob, filename + '.json')
}
