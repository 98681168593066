import _ from 'lodash'
import { DateTime } from 'luxon'

import { Policy } from './types'
import MdsPolicyStore from '../stores/mdsPolicyStore'

export const getPolicy = (
  regionId: string,
  mdsPolicy: Policy,
  timezone: string,
  mdsPolicyStore: MdsPolicyStore
) => {
  const policy_uuid = mdsPolicy.policy_id
  const name = mdsPolicy.name
  const description = mdsPolicy.description

  let startDate =
    mdsPolicy.start_date && DateTime.fromSeconds(mdsPolicy.start_date / 1000).setZone(timezone)
  let endDate =
    mdsPolicy.end_date && DateTime.fromSeconds(mdsPolicy.end_date / 1000).setZone(timezone)

  // rules are the same except for geographies so use the first one
  const rule = mdsPolicy.rules[0]

  const now = DateTime.local()
  const active = startDate <= now && (!endDate || endDate >= now)
  // ignore if in the past
  const past = endDate && endDate < now
  if (past) return null

  const vehicleTypes = rule.vehicle_types
  const days = rule.days
  const minimum = rule.minimum
  const maximum = rule.maximum
  const ruleType = rule.rule_type
  const ruleUnits = rule.rule_units
  const rateAmount = rule.rate_amount
  const rateRecurrence = rule.rate_recurrence

  const geographyIds = _.uniq(mdsPolicy.rules.map(rule => rule.geographies[0]))

  // combine all geography_json.features
  let features = geographyIds.map(
    (geoId: string) => mdsPolicyStore.geographies.get(geoId)?.geography_json.features
  )
  features = _.flatten(features)
  let fullPolicy = {
    uuid: policy_uuid,
    regionId: regionId,
    name: name,
    description: description,
    startDate: startDate,
    endDate: endDate,
    startTime: rule.start_time,
    endTime: rule.end_time,
    vehicleTypes: vehicleTypes,
    days: days,
    minimum: minimum,
    maximum: maximum,
    ruleType: ruleType,
    ruleUnits: ruleUnits,
    rateAmount: rateAmount,
    rateRecurrence: rateRecurrence,
    geojson: {
      type: 'FeatureCollection',
      features: features,
    },
  }

  if (active)
    return {
      ...fullPolicy,
      active: true,
    }

  return { ...fullPolicy, future: true }
}
