import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card } from 'semantic-ui-react'
import numeral from 'numeral'
import _ from 'lodash'

import { parseAndFmtTime, fmtDateRange } from '../../utils/detailsCardUtils'
import { PolicyWithGeo } from '../../model/types'
import Details from '../../components/Details'

const PoliciesDetails = ({
  isSelected,
  onClick,
  item: {
    uuid,
    name,
    description,
    startDate,
    endDate,
    startTime,
    endTime,
    vehicleTypes,
    days,
    minimum,
    maximum,
    ruleType,
    ruleUnits,
    rateAmount,
    rateRecurrence,
  },
}: {
  isSelected: boolean
  onClick: () => void
  item: PolicyWithGeo
}) => {
  const { t } = useTranslation()
  let cardDescription = []
  if (startDate) cardDescription.push([t('label.dates'), fmtDateRange(startDate, endDate)])
  if (!_.isEmpty(days))
    cardDescription.push([
      t('label.days'),
      days.map((day: string) => t(`weekdays.${day}`)).join(', '),
    ])
  if (!_.isEqual(startTime, endTime)) {
    if (startTime)
      cardDescription.push([
        t('label.start_time'),
        parseAndFmtTime(startTime.substring(0, startTime.length - 3)),
      ])
    if (endTime)
      cardDescription.push([
        t('label.end_time'),
        parseAndFmtTime(endTime.substring(0, endTime.length - 3)),
      ])
  }
  if (ruleType) cardDescription.push([t('label.rule_type'), t(`mds_rule_type.${ruleType}`)])
  if (ruleUnits) cardDescription.push([t('label.rule_units'), t(`mds_rule_units.${ruleUnits}`)])
  if (_.isNumber(minimum)) cardDescription.push([t('label.minimum'), minimum.toString()])
  if (_.isNumber(maximum)) cardDescription.push([t('label.maximum'), maximum.toString()])
  if (_.isNumber(rateAmount))
    cardDescription.push([t('label.rate_amount'), numeral(rateAmount / 100).format('$0.00')])
  if (rateRecurrence)
    cardDescription.push([t('label.rate_recurrence'), t(`mds_rate_recurrence.${rateRecurrence}`)])
  if (vehicleTypes)
    cardDescription.push([
      t('label.vehicle_types'),
      vehicleTypes.map((vehicleType: string) => t(`vehicle_classes.${vehicleType}`)).join(', '),
    ])

  return (
    <Card
      fluid
      as="div"
      onClick={onClick}
      className={['closure-details', isSelected && 'closure-details-selected']
        .filter(Boolean)
        .join(' ')}
    >
      <Card.Content>
        <Card.Header>{name}</Card.Header>
        <Card.Meta>{description}</Card.Meta>
        <Card.Description>
          <dl>
            {cardDescription.map((item: string[]) => {
              const label = item[0]
              const content = item[1]
              return <Details label={label} content={content} key={label} />
            })}
          </dl>
        </Card.Description>
      </Card.Content>
    </Card>
  )
}

export default PoliciesDetails
