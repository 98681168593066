import { action, computed, makeObservable, observable } from 'mobx'
import fetchFile from '../utils/fetchFile'
import { createContext, useContext } from 'react'
import MdsPolicyStore from './mdsPolicyStore'
import { Keyable } from '../model/types'

class RootStore {
  regions = observable.map({}, { deep: false })
  regionLayers = observable.map({}, { deep: false })
  mdsPolicyStore: MdsPolicyStore

  constructor() {
    makeObservable(this)
    this.mdsPolicyStore = new MdsPolicyStore(this)
  }

  @action
  initialize() {
    this.regions.clear()
    this.regionLayers.clear()
    this.fetchRegions()
    this.fetchRegionLayers()
  }

  @computed
  get loaded() {
    // This regionLayers thing was based on the
    // organizations.organization_data['data']['street_manager']['layers']
    // field in the database that got deleted as part of deprecating
    // street manager in PR #4548. It doesn't seem to have any purpose
    // now that our public maps only have policies (no streets or curbs)
    // and I think we can fully clear it out of the public maps, but for
    // now I'm commenting out this check so that the public map
    // can load in the short term while we put together a PR deleting
    // everything to do with the regionLayers.
    return this.regions.size > 0 // && this.regionLayers.size > 0
  }

  @action
  setRegionLayers(regionLayers: Keyable) {
    this.regionLayers.replace(regionLayers)
  }

  fetchRegionLayers() {
    fetchFile('region_layers.json')
      .then(regionLayers => {
        this.setRegionLayers(regionLayers)
      })
      .catch(() => {
        alert('Error fetching regions')
      })
  }

  @action
  setRegions(regions: Keyable) {
    this.regions.replace(regions)
  }

  @action
  setRegion(regionId: string, region: any) {
    this.regions.set(regionId, region)
  }

  getRegion(regionId: string) {
    return this.regions.get(regionId)
  }

  fetchRegions() {
    fetchFile('regions.json')
      .then(regions => {
        this.setRegions(regions)
      })
      .catch(() => {
        alert('Error fetching regions')
      })
  }

  getRegions = () => [...this.regions.keys()].sort()

  getMaps = (uuid: string) => this.regions.get(uuid)?.public_maps

  getText = (uuid: string) => this.regions.get(uuid)?.text

  getTimezone = (uuid: string) => this.regions.get(uuid)?.timezone
}

export const RootStoreContext = createContext<RootStore>(new RootStore())

export const useRootStore = () => useContext(RootStoreContext)

export default RootStore
