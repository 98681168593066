import _ from 'lodash'
import { DateTime } from 'luxon'

/**
 * @param startDate Luxon Datetime object
 * @param endDate Luxon Datetime object
 *
 * @returns Date range string formatted according to the browser's locale
 *           e.g. "2/2/2020 - 2/3/2021"
 */
export function fmtDateRange(startDate, endDate) {
  const startDateFormatted = startDate.toLocaleString(DateTime.DATE_SHORT)

  if (!endDate) return startDateFormatted + ' -'
  if (_.isEqual(startDate, endDate)) return startDateFormatted

  return `${startDateFormatted} - ${endDate.toLocaleString(DateTime.DATE_SHORT)}`
}

/**
 * @param str A 24-hour time string with format "HH:MM"
 *
 * @returns Input string formatted according to the browser's locale
 *           e.g. "17:00" -> "5:00 PM"
 */
export function parseAndFmtTime(str) {
  let [hour, minute] = str.split(':')
  if (!minute) minute = '0'

  hour = parseInt(hour, 10)
  minute = parseInt(minute, 10)

  return DateTime.local().set({ hour, minute, second: 0 }).toLocaleString(DateTime.TIME_SIMPLE)
}
