import { PolicyWithGeo } from '../model/types'
import { FILL_COLOR } from '../mapbox/MapStyles'

export const policiesFeatureCollection = (items: PolicyWithGeo[], selected_uuid: string) => {
  const selectedItem = items.find(item => item.uuid === selected_uuid)
  const fillColor = selectedItem
    ? selectedItem.active
      ? FILL_COLOR.active
      : FILL_COLOR.future
    : FILL_COLOR.normal

  if (!selectedItem) return null
  return {
    type: 'FeatureCollection',
    features: selectedItem.geojson.features.map(feature => {
      feature.properties
        ? (feature.properties['policy_uuid'] = selectedItem.uuid)
        : (feature.properties = { policy_uuid: selectedItem.uuid })
      feature.properties['color'] = fillColor

      return feature
    }),
  }
}
