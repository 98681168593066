import { action, computed, makeObservable, observable } from 'mobx'
import fetchFile from '../utils/fetchFile'
import { getPolicy } from '../model/policies'
import { Geography, Policy } from '../model/types'
import _ from 'lodash'
import RootStore from './rootStore'

class MdsPolicyStore {
  policies = observable.map({}, { deep: false })
  geographies = observable.map({}, { deep: false })
  regionId: string = 'unset'
  rawPolicyData: any
  rawGeographyData: any
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  initialize(regionId: string) {
    this.regionId = regionId
    this.fetchPolicies(regionId)
    this.fetchGeographies(regionId)
  }

  setRegionId(regionId: string) {
    this.regionId = regionId
  }

  @computed
  get loaded(): boolean {
    return this.policies.size > 0 && this.geographies.size > 0
  }

  @action
  setPolicies(policies: Policy[]) {
    const policiesMap = _.keyBy(policies, 'policy_id')
    this.policies.replace(policiesMap)
  }

  fetchPolicies(regionId: string) {
    this.policies.clear()
    this.rawPolicyData = null
    fetchFile('mds_policies.json')
      .then(policies => {
        if (!policies[regionId]) {
          alert('Policies not found for region')
        } else {
          this.rawPolicyData = policies[regionId]
          this.setPolicies(policies[regionId].data.policies)
        }
      })
      .catch(err => {
        console.log('Error fetching policies')
        throw err
      })
  }

  @action
  setGeographies(geographies: Geography[]) {
    const geographiesMap = _.keyBy(geographies, 'geography_id')
    this.geographies.replace(geographiesMap)
  }

  fetchGeographies(regionId: string) {
    this.geographies.clear()
    this.rawGeographyData = null
    fetchFile('mds_geographies.json')
      .then(geographies => {
        if (!geographies[regionId]) {
          alert('Geographies not found for region')
        } else {
          this.rawGeographyData = geographies[regionId]
          this.setGeographies(geographies[regionId].data.geographies)
        }
      })
      .catch(err => {
        console.log('Error fetching geographies')
        throw err
      })
  }

  get timezone(): string {
    return this.rootStore.getTimezone(this.regionId)
  }

  @computed
  get policiesArray(): any {
    return [...this.policies.values()]
      .map((policy: Policy) =>
        getPolicy(this.regionId, policy, this.timezone, this.rootStore.mdsPolicyStore)
      )
      .filter(Boolean)
  }

  getPolicy(policy_uuid: string): any {
    const policy = this.policies.get(policy_uuid)
    return getPolicy(this.regionId, policy, this.timezone, this.rootStore.mdsPolicyStore)
  }

  @computed
  get orderedPolicies(): any {
    return _.orderBy(this.policiesArray, [i => i.name.toLowerCase(), 'start_date'], ['asc', 'desc'])
  }
}

export default MdsPolicyStore
