import React from 'react'

/**
 * @param label - label text
 * @param content - content text
 * @return a formatted react fragment
 */
export default function Details({ label, content }: { label: string; content: string }) {
  return (
    <>
      <dt>{label}:</dt>
      <dd>{content}</dd>
      <div style={{ flexBasis: '100%' }} />
    </>
  )
}
