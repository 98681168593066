// Use public data bucket in production, local files for local dev
const DATA_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://public-prod-us.populus.ai/public_map_data/'
    : process.env.PUBLIC_URL + '/data/'

const fetchFile = filename => {
  return new Promise((resolve, reject) => {
    try {
      fetch(DATA_URL + filename).then(response => resolve(response.json()))
    } catch (e) {
      reject(e)
    }
  })
}

export default fetchFile
