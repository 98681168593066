import React from 'react'
import { useTranslation } from 'react-i18next'

import MultipleDataDownloadButton from './MutlipleDataDownloadButton'

import { downloadMds } from '../utils/downloadUtils'
import { useRootStore } from '../stores/rootStore'

const Header = ({
  match: {
    params: { region_id, type },
  },
}: {
  match: {
    params: {
      region_id: string
      type: string
    }
  }
}) => {
  const { t } = useTranslation()
  const rootStore = useRootStore()
  const regionName = rootStore.getText(region_id)
  const { mdsPolicyStore } = useRootStore()

  const interestedInDataLink = (
    <a
      href="https://www.populus.ai/apis/registration"
      rel="noopener noreferrer"
      target="_blank"
      style={{ color: '#71BCF2', fontSize: '12px' }}
    >
      {t('interested_in_this_data')}
    </a>
  )

  const downloadPolicies = (
    <div>
      <MultipleDataDownloadButton
        absolutePosition={false}
        dataObjects={[
          {
            filename: t('mds_policies'),
            format: 'geojson',
            downloadFunction: () =>
              downloadMds(`${t('mds_policies')} - ${region_id}`, mdsPolicyStore.rawPolicyData),
          },
          {
            filename: t('mds_geographies'),
            format: 'geojson',
            downloadFunction: () =>
              downloadMds(
                `${t('mds_geographies')} - ${region_id}`,
                mdsPolicyStore.rawGeographyData
              ),
          },
        ]}
      />
    </div>
  )

  return (
    <>
      <h2>
        {regionName && regionName + ' '}
        {type === 'policies' && t('policies')}
      </h2>
      <div style={{ textAlign: 'right', float: 'right' }}>
        {type === 'policies' ? downloadPolicies : interestedInDataLink}
      </div>
    </>
  )
}

export default Header
