const config = {
  mapbox: {
    accessToken:
      'pk.eyJ1IjoicG9wdWx1c2N0byIsImEiOiJjanZwZ2Z4c2kxc3FwNDhxamJzZ3Y5eGc0In0.oLbFDiFn0Adsm3YN3Tpr5Q',
  },
  firebase: {
    apiKey: 'AIzaSyAKqG72EGqEtCTne1xBO-PBOoENqtAEDTk',
    authDomain: 'populus-prod-us.firebaseapp.com',
    projectId: 'populus-prod-us',
    storageBucket: 'populus-prod-us.appspot.com',
    messagingSenderId: '332578102181',
    appId: '1:332578102181:web:094d4a0e0c00512f5be64c',
  },
}

export default config
